export class ApiUtils {
  public static encodeMSISDNList(msisdn_list: string[]): string[] {
    for (let i = 0; i < msisdn_list.length; i++) {
      msisdn_list[i] = encodeURIComponent('+') + msisdn_list[i].slice(1);
    }
    return msisdn_list;
  }

  public static calculateCircleArea(
    center: google.maps.LatLng | google.maps.LatLngLiteral,
    radius: number
  ): number {
    return google.maps.geometry.spherical.computeArea(
      new google.maps.Circle({
        center,
        radius,
      })
    );
  }

  public static calculatePolygonArea(
    path:
      | (google.maps.LatLng | google.maps.LatLngLiteral)[]
      | google.maps.MVCArray<google.maps.LatLng | google.maps.LatLngLiteral>
      | google.maps.Circle
      | google.maps.CircleLiteral
      | google.maps.LatLngBounds
      | google.maps.LatLngBoundsLiteral
  ): number {
    return google.maps.geometry.spherical.computeArea(path);
  }
}
